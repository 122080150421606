import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="勤務環境／ワークサポート"
      description="株式会社アイテクスの勤務環境／ワークサポートをご案内致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">勤務環境／ワークサポート</h1>
          <h2 className="subtitle">Support</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container has-text-centered">
        <h1 className="title">ワークサポート</h1>
        <h2 className="subtitle">Work Support</h2>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-skillup.png"
                alt="スキルアップ支援制度"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>スキルアップ支援制度</h3>
            <p>
              規定の金額を上限に書籍購入、IT機器、セミナー・イベント参加を事前申請なく購入・利用することができます。
            </p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-birthday.png"
                alt="バースデー休暇制度"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>バースデー休暇制度</h3>
            <p>
              本人の誕生日（休日の場合は前後の営業日）に有給を取得できる制度です。
            </p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-tanjobi.png"
                alt="誕生日祝い金"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>誕生日祝い金</h3>
            <p>自分の誕生日に会社から祝い金を受け取ることが出来ます。</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-koibito.png"
                alt="恋人作り応援特別休暇制度"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>恋人作り応援特別休暇制度</h3>
            <p>
              独身社員は、恋愛対象者に交際申し込み、またプロポーズをする日に有給休暇を優先して取得することができます
            </p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-zuru.png"
                alt="ずる休み制度"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>ずる休み制度</h3>
            <p>気分が乗らない時に、当日でも有給を取得することが出来ます。</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-4-widescreen is-6-desktop">
            <div className="block">
              <StaticImage
                src="../../content/assets/environments-gourmetstar.png"
                alt="グルメスター制度"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <h3>グルメスター制度</h3>
            <p>
              社員の親睦を深めることを目的として、行きたい飲食店でランチをしてアイテクス独自の食レポを実施する制度です。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium">
      <div className="container">
        <div className="block has-text-centered">
          <h1 className="title">勤務環境</h1>
          <h2 className="subtitle">Working Environment</h2>
        </div>
        <div className="columns is-variable is-5 is-vcentered mt-6">
          <div className="column">
            <StaticImage
              src="../../content/assets/16x9-environments-switch-workspace.jpg"
              alt="シェアオフィス「Switch」のワークスペース"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <div className="content">
              <h3>ワークスペース</h3>
              <p>
                当社が運営しているシェアオフィス「Switch」で快適に作業をすることが出来ます。
              </p>
            </div>
          </div>
        </div>
        <div className="columns is-variable is-5 is-vcentered mt-6">
          <div className="column">
            <div className="content has-text-right-tablet">
              <h3>応接室</h3>
              <p>
                複数人数での利用から1on1での利用、またリモート会議も行う事が来ます。
                <br />
                応接室には、リモート会議がスムーズに出来るようモニターを設けています。
              </p>
            </div>
          </div>
          <div className="column">
            <StaticImage
              src="../../content/assets/16x9-environments-switch-reception.jpg"
              alt="シェアオフィス「Switch」の応接室"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
        <div className="columns is-variable is-5 is-vcentered mt-6">
          <div className="column">
            <StaticImage
              src="../../content/assets/16x9-environments-tenmonkan.jpg"
              alt="オフィス周辺-天文館"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className="column">
            <div className="content">
              <h3>オフィス周辺</h3>
              <p>
                天文館の市街地が近く、天文館電停から徒歩5分のエリアに位置しています。
                <br />
                オフィス周辺には飲食店も立ち並んでいます。
              </p>
            </div>
          </div>
        </div>
        <div className="columns is-variable is-5 is-vcentered mt-6">
          <div className="column">
            <div className="content has-text-right-tablet">
              <h3>リモートワーク可能</h3>
              <p>
                弊社ではリモートワークを推奨しております。
                <br />
                また、いつでも本社やシェアハウスに出社可能です。
                <br />
                通勤に時間を割きたくない方や子育て世代の方に
                <br />
                時間を有効に使っていただけるよう 日々、働き方を改善しています。
              </p>
            </div>
          </div>
          <div className="column">
            <StaticImage
              src="../../content/assets/16x9-chiringashima.jpg"
              alt="フルリモートワーク"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <h1 className="title">技術情報</h1>
        <h2 className="subtitle">Technology Information</h2>
        <div className="content">
          <div className="table-container">
            <table className="table">
              <tbody>
                <tr>
                  <td>開発言語</td>
                  <td>C#、C++、C、VB.NET、Access、VB6.0、PHP、Java　など</td>
                </tr>
                <tr>
                  <td>データベース</td>
                  <td>Oracle、SQLServer、PostgreSQL、MySQL、MariaDB　など</td>
                </tr>
                <tr>
                  <td>OS関連</td>
                  <td>
                    Windows、Linux（RHEL、CentOS）および、関連スクリプト　など
                  </td>
                </tr>
                <tr>
                  <td>クラウド</td>
                  <td>
                    AWS(Amazon)、Microsoft Azure
                    <br />
                    その他専用クラウドサービスおよび、レンタルサービス
                  </td>
                </tr>
                <tr>
                  <td>AI</td>
                  <td>Python、Tensorflow、Ruby On Rails</td>
                </tr>
                <tr>
                  <td>アプリ</td>
                  <td>Monaca</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="content mt-6">
          <h3>開発言語</h3>
        </div>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>フロントエンド</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="HTML5"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-html-5.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="jQuery"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-jquery.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Node.js"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-nodejs.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="React"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-react.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>バックエンド</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="C#"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-c-sharp.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Java"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-java.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="content mt-6">
          <h3>インフラ</h3>
        </div>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>OS</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="Windows"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-microsoft-windows.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Windows"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-macOS.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Windows"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-android.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="iOS"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-ios.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>データベース</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="Microsoft SQL Server"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-microsoft-sql-server.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="MySQL"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-mysql.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="PostgreSQL"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-postgresql.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>Git</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="GitLab"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-gitlab.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dt>クラウド</dt>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="Docker"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-docker.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="AWS"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-aws.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Azure"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-azure.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="content mt-6">
          <h3>AI</h3>
        </div>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="Python"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-python.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Tensorflow"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-tensorflow.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                    <span className="level-item">
                      <StaticImage
                        alt="Ruby On Rails"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-ruby_on_rails.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="content mt-6">
          <h3>アプリ</h3>
        </div>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet">
            <dl>
              <dd className="mt-3">
                <div className="level is-mobile">
                  <div className="level-left">
                    <span className="level-item">
                      <StaticImage
                        alt="Monaca"
                        as="figure"
                        className="image is-64x64"
                        src="../../content/assets/icon-monaca.png"
                        layout="fullWidth"
                        placeholder="blurred"
                      />
                    </span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
